import React from 'react';
import { Link } from 'gatsby';


export const DocsPreview = () => {
  return (
    <div className="text-light py-5 bg-gray-900 text-gray-100" style={{ minHeight: '100vh' }}>
      <div className="container">
        <div className="hero mt-5">
          <div className="max-w-3xl mx-auto">
            <h2 className="text-center mb-4 text-3xl font-bold sm:text-4xl xl:text-5xl">
              Create the perfect webpage you visualized in 2 minutes.
            </h2>
            <h5 className="text-center mb-5 mx-auto" style={{ maxWidth: '650px' }}>
              Get projects to production faster than ever thought possible with very minimal effort.{' '}
            </h5>
          </div>
          <div>
            <img
              alt="docsImage"
              width="100%"
              height="auto"
              src={require('../../../components/images/edit.gif')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
