import React from 'react';
import { Link } from 'gatsby';


export const Hero3 = () => {

  return (
    <div className="container">
      <div className="feature feature-2">
        <div className="feature__wrap">
          <div className="feature__left">
            <img
              src={require('../../../components/images/awindframeimg2.png')}
              alt="#"
              className="feature__img"
            />
          </div>
          <div className="feature__right">
            <h2 className="feature__lead feature__lead--1 showcase__lead">
               Customize endlessly, Preview and Export Templates
            </h2>
            <p className="feature__text mb-5">
              Customize pre built blocks to match your brand with a numerous selection of customizable properties. Preview the template/ webpage you created and export the code when you are satisfied with what you have designed.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
